.task-container {
  padding: 20px;
}
.task-header {
  margin-bottom: 20px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }
}
.task-content {
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
}
.task-info {
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 20px;
  background: #fff;
  flex: 1 0 0;

  .form-group {
    margin: 0;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
  }

  label {
    font-size: 14px;
    display: inline-block;
    width: 200px;
  }
  p {
    display: inline-block;
    margin: 0;
  }
}
.task-photo {
  img,
  .img-placeholder {
    border-radius: 10px;
    font-family: "Helvetica", serif;
    font-weight: 300;
    line-height: 2;
    text-align: center;
    max-width: 200px;
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    min-width: 300px;
    min-height: 100%;
    cursor: zoom-in;

    &:before {
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: #fff;
      border-radius: 10px;
    }

    &:after {
      content: "\f127"" " attr(alt);
      display: block;
      font-size: 16px;
      font-style: normal;
      font-family: FontAwesome;
      color: rgb(100, 100, 100);
      position: absolute;
      top: 45%;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }
}

.task-answers-list {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: -20px;
}
.task-answer {
  width: calc(100% / 4 - 20px);
  background: var(--color-white);
  position: relative;
  overflow: hidden;

  &-photo-container {
    overflow: hidden;
    height: auto;
    display: flex;
    align-items: flex-start;
    position: relative;
    min-height: 40px;

    img {
      max-width: 100%;
      min-height: 200px;

      // &:before {
      //   content: " ";
      //   display: block;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   height: 100%;
      //   width: 100%;
      //   background: #fff;
      //   border-radius: 10px;
      // }

      // &:after {
      //   content: "\f127"" " attr(alt);
      //   display: block;
      //   font-size: 16px;
      //   font-style: normal;
      //   font-family: FontAwesome;
      //   color: rgb(100, 100, 100);
      //   position: absolute;
      //   top: 45%;
      //   left: 0;
      //   width: 100%;
      //   text-align: center;
      // }
    }
  }

  &-menu {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    //display: none; // TODO hide until task in progress
  }
  &-menu-button {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 5px 0;
    color: #fff;
    cursor: pointer;
    border: 3px solid var(--newBlue);

    &--in-progress {
      > svg {
        animation: spin 2s linear infinite;
      }
    }
  }

  &-info {
    width: 100%;
    //height: 0;
    overflow: hidden;
    transition: height 1s ease-in-out;
    padding: 10px;
    height: auto;
    background: var(--color-white);
  }
  &-info-checking {
    gap: 10px;
    justify-content: space-between;
  }
}
.tags-wrapper {
  span {
    background: #fce05b;
    color: #000;
    font-weight: 400;
    padding: 5px 8px;
  }
}
.task-answer-info {
  p {
    word-break: break-all;
    margin-bottom: 0;
  }
}

.task-answer-photo-container {
  .img-placeholder {
    &:hover {
      cursor: zoom-in;
    }
  }
}
@media print {
  //* {
  //  -webkit-print-color-adjust: exact;
  //}
  #aside,
  .app-header,
  .print-report-container {
    display: none;
  }
  .task-container {
    .task-header {
      display: none;
    }
  }
  .task-answers-list {
    display: block;
  }
  .task-answer {
    page-break-before: always;
    width: 100%;
  }
  .task-photo {
    flex: 1 0 0;
  }
  .task-answer-photo-container {
    overflow: visible;
    page-break-before: always;
    height: auto;
    max-height: 1300px; // tested on Chrome Ubuntu 20, 1080h
  }
  .task-answer-info {
    position: relative;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
