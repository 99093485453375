@import url("https://fonts.googleapis.com/css2?family=Lexend&display=swap");

//variables
$filter-max-width: 1413px;

body {
  &.opened {
    overflow: hidden;
  }
}

.photo-review-list {
  display: flex;
  flex-wrap: wrap;
}

.photo-review-item {
  width: 215px;
  height: auto;
  overflow: hidden;
  display: inline-block;
  margin: 10px;
  cursor: pointer;
  position: relative;
  border: 5px solid rgba(154, 156, 158, 255);
  color: #fff;
  font-size: 14px !important;

  &:hover,
  &.photo-review-item--active,
  &:hover:after,
  &.photo-review-item--active:after {
    border-color: var(--newBlue);
  }

  .photo-review-item-wrapper {
    position: relative;
    padding: 0;
    height: 266px;
    overflow: hidden;
  }

  .review-item {
    position: relative; /* To position the approval component relative to the container */
    padding: 0; /* Adjust spacing around the item */
  }

  .review-item-text {
    width: 100%; /* Ensures the text takes full width */
  }

  .photo-review-item-content {
    background: rgba(154, 156, 158, 255);
    margin: 0;
    width: 100%;
    word-wrap: break-word;
    padding: 0 5px;
  }

  .first-item {
    padding-top: 5px;
  }

  .status-square {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    color: white;
    font-size: 12px;
    font-weight: bold;
  }

  .status-square.approved {
    background-color: green;
  }

  .status-square.rejected {
    background-color: red;
  }

  .root-container {
    background-color: rgba(154, 156, 158, 255);
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .review-item,
  .tags-group-table {
    flex-shrink: 0;
  }

  .visit-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background: rgba(154, 156, 158, 255);
    padding: 2px 5px;
    margin-top: auto;
  }

  .user-name,
  .time-elapsed {
    color: rgb(244, 244, 244);
    font-weight: 500;
    font-size: 12px !important;
  }

  .tags-form-group {
    text-align: left;

    &.tags-group-content {
      display: flex;
      .tags-container {
        display: flex;
        flex-wrap: nowrap;
        width: 185px;
        overflow: hidden;
        white-space: nowrap;
        align-items: center;
        position: relative;
      }

      .tag-label {
        display: inline-block;
        margin-right: 4px;
        white-space: nowrap;

        span {
          padding: 0px;
          display: inline-block;
          background: #fce05b;
          color: #000;
          border-radius: 1px;
          padding: 0.5px 0px !important;
          font-size: 12px;
          font-weight: 400;
          white-space: nowrap;
        }
      }

      .tags-container.overflowing .tag-ellipsis {
        display: inline-block;
      }

      .tag-ellipsis {
        display: flex;
        justify-content: flex-end;
        margin-left: auto;

        span {
          background: #fce05b !important;
          color: #000;
          font-size: 13px;
          font-weight: 400;
          border-radius: 1px;
          padding: 0px 4px 0.5px 4px !important;
          margin-top: 3px;
          margin-bottom: 2px;
          margin-left: auto;
        }
      }
    }
  }

  &.photo-review-item--export-mode:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--lightBlue);
    z-index: 2;
    pointer-events: none;
  }

  .data-loader {
    position: absolute;
    z-index: 3;
    top: -20px;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    align-content: center;
    width: 100%;
    align-content: center;
    // padding-top: 50%;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    min-height: 100%;
    image-orientation: none !important;
    object-fit: fill;
    object-position: center;

    &.ios-photo {
      transform: rotate(270deg);
      scale: 1.4;
    }
  }
  .photo-review-item-to-download {
    position: absolute;
    bottom: 3px;
    right: 3px;
    z-index: 5;
  }
  //slider styles for review single item
  .single-details-buttons {
    display: flex;
    flex-wrap: nowrap;

    &.details-buttons-container {
      padding: 0px !important;
      margin: 0px !important;
      gap: 8px !important;

      .allowed-button {
        .btn.acceptBtn {
          color: #fff !important;
          height: 40px;
          width: 100%;
          margin-right: 0%;
          box-shadow: none !important;

          &:hover {
            i {
              color: #fff;
            }
          }

          &.is-approval {
            color: green !important;

            &:hover {
              color: green !important;
            }
          }
        }
      }

      .cancel-button {
        .btn.rejectBtn {
          color: #fff !important;
          height: 40px;
          width: 100%;
          margin-left: 0%;
          box-shadow: none !important;

          &:hover {
            i {
              color: #fff;
            }
          }

          &.not-approval {
            color: red !important;

            &:hover {
              color: red !important;
            }
          }
        }
      }
    }
  }

  .details-buttons-container {
    display: none;
    flex-wrap: wrap;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    background: linear-gradient(0, rgba(0, 0, 0, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 2;
    padding: 0px !important;
    margin: 0px !important;
    gap: 8px !important;
    box-shadow: none !important;

    .btn {
      height: 40px;
      width: 100%;
      box-shadow: none;
      color: #fff !important;
      .fa {
        margin: 0;
        font-size: 20px;
      }
    }

    .cancel-button {
      order: 2;
      text-align: left;
      background-color: #e3071229;
      margin-left: 0%;
      box-shadow: none !important;

      .rejectBtn {
        padding: 0;
        font-size: 0;
        background: none;
        border: 0;
        box-shadow: none !important;
      }

      &.not-approval {
        .rejectBtn {
          i {
            color: red !important;
          }
        }
      }
    }

    .allowed-button {
      order: 1;
      text-align: right;
      background-color: #00968733;
      margin-right: 0%;
      box-shadow: none !important;

      .acceptBtn {
        padding: 0;
        font-size: 0;
        background: none;
        border: 0;
        outline: none;
        box-shadow: none !important;
      }

      &.is-approval {
        .acceptBtn {
          i {
            color: green !important;
          }
        }
      }
    }
  }

  .photo-list {
    display: flex;
    width: 100%;
    overflow: hidden;
    scroll-behavior: smooth;
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 1;
    position: relative;
    min-width: 210px;
    max-width: 400px;
    margin: 0 auto;

    .previewImage.active {
      display: inline-block;
      opacity: 1;
      z-index: 2;
    }
  }

  .dot-list {
    display: none;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    .dot {
      height: 5px;
      width: 5px;
      margin: 0 0 0 3px;
      background-color: #ccc;
      border-radius: 50%;
      display: inline-block;
      border: 1px solid #ccc;
      transition: border-color 0.6s ease;

      &:first-child {
        margin-left: 0;
      }

      &.active {
        background: #fff;
      }
    }
  }

  .carousel-navigations {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    top: 0;
    left: 0;
    padding: 0;
    background-color: transparent;
    pointer-events: none;
  }

  .side-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 100%;
    background-color: transparent;
    pointer-events: auto;
    transition: background-color 0.3s ease;
  }

  .side-nav:hover {
    background-color: rgba(255, 255, 255, 0.159);
  }

  .slide-arrow {
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.5);
    font-size: 24px;
    cursor: pointer;
    transition: color 0.3s ease;
  }

  .side-nav:hover .slide-arrow {
    color: rgba(241, 2, 2, 0.705);
  }

  .slide-arrow:focus {
    outline: none;
  }

  .slide-arrow i {
    pointer-events: none;
  }

  .side-nav:active {
    background-color: rgba(255, 255, 255, 0.223);
  }

  .side-nav .slide-arrow {
    color: rgba(255, 187, 187, 0.411);
  }

  &:hover {
    .carousel-navigations,
    .dot-list,
    .details-buttons-container {
      display: flex;
    }
  }
}

.photo-review-details {
  .form-group {
    display: flex;
    align-items: center;
    gap: 8px;
    label {
      width: 110px;
      flex-shrink: 0;
    }

    p {
      display: inline-block;
      margin: 0;
    }
  }

  .photo-review-content {
    border-radius: 10px;
    background: #fff;
    padding: 10px;

    span {
      display: inline-block;
    }

    label {
      padding: 0;
      font-weight: 600;
      font-size: 13px;
      margin: 0;
      max-width: 130px;
      width: 100%;
    }

    .avatar-crop {
      margin-right: 7px;
    }

    .form-group {
      border-top: 1px solid #e0e0e0;
      margin: 0;
      padding: 7px 0;

      &:first-of-type {
        border: 0;
      }
    }
  }

  .photo-review-header {
    margin-bottom: 16px;

    &.monitoring-header {
      margin: 14px 0 6px;

      .form-group {
        margin-bottom: 0;
      }
    }

    .point-logo {
      width: 100%;
      max-width: 46px;
      height: auto;
      margin-right: 10px;
    }

    .form-group {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .title-block {
      font-weight: 600;
      margin-bottom: 2px;
      font-size: 14px;
    }

    .point-location {
      a {
        display: inline-block;
        font-weight: 600;
        margin-bottom: 2px;
        font-size: 14px;
      }

      p {
        width: 100%;
      }
    }

    .tags-group {
      margin-bottom: 0;

      .label {
        background: #fce05b;
        color: #000;
        font-weight: 400;
        padding: 5px 8px;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }
}

.photo-review-container {
  .new-task-wrapper {
    .edit-images-block {
      display: none !important;
    }
  }
  .actions-block .cancel-image-popup {
    display: block;
  }
}

.new-task-wrapper {
  h4 {
    margin-bottom: 16px;
  }

  .box-body {
    &.boxForm {
      background: none;
      border-radius: 0;
      padding: 0;
    }

    .row {
      padding: 10px 0;
      margin: 0;
      border-radius: 10px;
      background: #fff;
    }
  }

  .actions-block {
    padding: 0;
    display: flex;
    justify-content: space-around;
  }
}

.details-buttons-container {
  display: flex;
  flex-wrap: wrap;

  div {
    position: relative;
    width: 100%;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    .btn {
      margin: 5px;
      white-space: nowrap;
    }
  }

  .allowed-button {
    order: 1;
  }
  .cancel-button {
    order: 2;
  }
  .prev-next-buttons-wrap {
    order: 3;
    flex-basis: 100%;
  }
  @media (min-width: 500px) {
    .allowed-button {
      text-align: right;
    }
    .cancel-button {
      text-align: left;
    }
  }

  @media (max-width: 767px) {
  }

  @media (min-width: 1700px) {
    .allowed-button {
      order: 1;
    }
    .cancel-button {
      order: 3;
    }
    .prev-next-buttons-wrap {
      order: 2;
      flex-basis: 0;
      flex-grow: 2;
    }
  }
}

.accordion-item {
  background: #efefef;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: $filter-max-width;
  padding: 4px 5px 0 10px;
  margin-bottom: 10px;

  .accordion-title {
    position: relative;
    font-size: 14px;
    padding-bottom: 3px;
    color: #7c7c7c;

    &:after {
      content: "";
      background: url(../../../assets/image/icon/down.png) no-repeat;
      background-size: contain;
      display: inline-block;
      width: 20px;
      height: 20px;
      position: absolute;
      right: 0;
      top: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.accordion-static-item {
  margin-bottom: 5px;
  max-width: $filter-max-width;
  width: 100%;

  .filter-first-line {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
  }
}

.modal-content {
  .task-main-content {
    .form-group {
      z-index: auto;
    }
  }
}

.photo-review {
  .filter-bar {
    .react-dropdown-container {
      width: calc(32.7% - 5px);
      margin: 0 0 5px 0.5%;
    }

    .filter-datepicker {
      margin: 0;
    }

    .react-select-custom-container,
    .custom-react-select__control,
    .custom-react-select__value-container {
      width: 100%;
    }

    .custom-react-select__control {
      &.custom-react-select__control--is-focused {
        z-index: 2;
      }
    }

    .user-custom-filter {
      width: calc(20% - 5px);
    }
  }

  .accordion-item-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .react-dropdown-container,
    .searchInput {
      width: 33%;
      margin: 0 0 10px 0;

      &.custom-products,
      &.custom-subcategories {
        width: 49.75%;
      }
    }

    &.hidden {
      display: none;
    }
  }

  .pointFilter.react-dropdown-container--multiselect {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .react-dropdown-container {
      width: 65.5%;

      &:first-child {
        width: 34.5%;
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .filter-navigations {
    display: flex;
    width: 100%;
    max-width: 1413px;

    .custom-filter-name {
      display: inline-block;
      margin-right: 5px;
    }
  }

  .hidden {
    display: none;
  }

  .visible {
    display: flex;
  }
}

.save-filter-nav {
  input {
    height: auto !important;
    min-height: 30px;
  }
}

.primary-red {
  border-color: red;
  background: red;
  color: #fff !important;
}

.btn-save-custom {
  border-color: #a0e0f4;
  color: #5a5a5a !important;
  background: #a0e0f4;
}

.asq-popup {
  padding: 15px;

  .asq-popup {
    border-radius: 3px;
    padding: 3px 8px;
    background: #a0e0f4;
    border: 1px solid #a0e0f4;

    &:hover {
      opacity: 0.7;
    }
  }

  .yes-asq {
    margin-right: 15px;
    background: red;
    border-color: red;
    color: #fff;
  }
}

.asq-modal {
  max-width: 350px !important;
}

.header-modal {
  position: absolute;
  top: 25px;
  right: 15px;
  color: #fff;
  font-size: 25px;

  i.fa-times {
    &:hover {
      cursor: pointer;
    }
  }
}

.modal-content {
  .image-editor-wrapper {
    .header-modal {
      top: 0;
    }
  }
}

.modal-content {
  background: #d9d9d9;
  border: none;
  border-radius: 2px;

  .modal-body {
    padding: 0;
  }

  .taskBlock {
    display: flex;
    flex-wrap: wrap;

    .box-body {
      &.boxForm {
        margin-bottom: 0;
      }
    }
  }

  .photoContainer {
    padding: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;

    .details-buttons-container {
      position: absolute;
      right: 8px;
      bottom: 0;
      width: 100%;
      background: linear-gradient(0, rgba(0, 0, 0, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
      padding: 10px 0;
      z-index: 2;
      display: flex;
      flex-wrap: nowrap;

      div {
        .btn {
          margin: 0;

          &.is-approval {
            color: #009688 !important;
          }

          &.not-approval {
            color: #e30713 !important;
          }
        }
      }
    }

    .taskImagesEdit {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 400px;
        width: 100%;
        height: auto;
        padding: 0;
      }

      .previewPhotoNav {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0;
        position: relative;

        div {
          margin: 0;
          right: 0;
        }

        .details-buttons-container {
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          align-content: center;
          width: auto;
          height: 100%;
          right: auto;
          left: 0;
          background: rgb(0 0 0 / 26%);
          padding: 0;

          .btn {
            margin: 0 !important;
            display: flex;
            align-items: center;
            padding: 0 3px 0 10px;

            &:hover,
            &:active {
              i {
                color: #0a6ea0;
              }
            }
          }
        }

        button {
          border: none;
          background: none;
          padding: 0;
          margin: 0;
          line-height: normal;
          position: absolute;
          right: 14px;
          top: 10px;

          &.button-edit-img {
            right: 55px;
          }

          i {
            font-size: 24px;
            line-height: 20px;
            vertical-align: top;
          }
        }

        .deleteImage {
          margin: 0 5px 0 10px;
        }

        .image-editor-wrapper {
          display: inline-block;
          position: static;

          .header-modal {
            top: 0;
            right: 15px;

            i {
              color: #e30713;
            }
          }
        }
      }

      .image-editor-wrapper {
        .canvas-layer {
          top: 50%;
          margin: 0;
          transform: translate(-50%, -50%);
        }

        .canvas-container {
          display: flex;
          margin: 0 auto;
          width: fit-content;
          align-items: center;
          justify-content: center;
          min-height: 350px;
        }
      }

      .previewPhotoNav {
        width: 100%;
        position: absolute;
        top: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
        min-height: 45px;
      }
    }
  }

  .save-image-popup,
  .cancel-image-popup {
    border: 1px solid var(--newBlue);
    border-radius: 7px;
    padding: 7px 15px;
    font-size: 14px;

    i {
      margin-right: 5px;
    }

    &:hover {
      border-color: var(--newBlue);
    }
  }

  .cancel-image-popup {
    background-color: #e30713;
    border-color: #e30713;
    display: none;

    &:hover {
      border-color: #e30713;
      background: #fff;
    }
  }

  .previewPhotoNav {
    background: linear-gradient(180deg, rgb(0 0 0 / 67%) 0%, rgba(255, 255, 255, 0) 100%);
    text-align: right;
    position: absolute;
    right: 8px;
    top: -1px;
    width: calc(100% - 8px);
    padding: 10px 14px;
    z-index: 3;
  }

  .btn {
    padding: 0;
    font-size: 0;
    background: none;
    border: 0;

    i {
      font-size: 25px;
      margin: 0 !important;
    }

    &.btn-primary {
      height: 50px;
      width: 80%;
    }

    &.acceptBtn {
      &:hover {
        background-color: #00968733;
        color: #009688 !important;
      }
    }

    &.rejectBtn {
      &:hover {
        background-color: #e3071229;
        color: #e30713 !important;
      }
    }
  }
}

.modal {
  backdrop-filter: blur(5px);

  .single-photo-review-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    z-index: -1;

    .btn {
      font-size: 0;
      padding: 0;
      margin: 0;
      background: #e9e9e9;
      border-radius: 50%;
      width: 32px;

      .fa {
        font-size: 28px;
        color: #848484;
        margin: 0 auto;
        line-height: normal;
        height: 30px;

        &.fa-angle-left {
          margin-right: 2px;
        }

        &.fa-angle-right {
          margin-left: 2px;
        }
      }

      &:disabled {
        border-color: transparent;
      }

      &:hover,
      &:active {
        background: var(--newBlue);

        .fa {
          color: #fff;
        }

        &:disabled {
          background: #e9e9e9;
          border-color: transparent;

          .fa {
            color: #848484;
          }
        }
      }
    }
  }

  &.show {
    .modal-dialog {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .hidden {
    display: none !important;
  }
}

.s-alert-wrapper {
  z-index: 1051;
  position: relative;
}

.photo-review-container {
  .task-main-row {
    padding: 0 !important;
    width: 100%;
    max-width: none;
    flex: 0 0 auto;
  }

  .modal-dialog {
    z-index: 3;
  }
}

//Slider photos in popup
// .photo-review-list {
//   display: flex;
//   width: 100%;
//   overflow: hidden;
//   scroll-behavior: smooth;
//   list-style: none;
//   margin: 0;
//   padding: 0;
//   z-index: 1;
//   position: relative;
//   min-width: 210px;
//   max-width: 440px;
//   margin: 0 auto;
// }
.original-photos-wrapper {
  .carousel-navigations {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    background-color: transparent;
    pointer-events: none;

    .side-nav {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 100%;
      background-color: transparent;
      pointer-events: auto;
      transition: background-color 0.3s ease;
    }

    .side-nav:hover {
      background-color: rgba(255, 255, 255, 0.159);
    }

    .slide-arrow {
      background: none;
      border: none;
      color: #4d4d4daa;
      font-size: 24px;
      cursor: pointer;
      transition: color 0.3s ease;
    }

    .side-nav:hover .slide-arrow {
      color: #4d4d4d;
    }

    .slide-arrow:focus {
      outline: none;
    }

    .slide-arrow i {
      pointer-events: none;
    }
  }

  .dot-list {
    top: 10px;
  }

  .openPhoto {
    display: none;

    &.active {
      display: inline-flex;
    }
  }

  .photo-visible {
    opacity: 1 !important;
  }
}

/*Toggle view*/
.switch-view {
  --_switch-bg-clr: var(--newBlue);
  --_switch-padding: 4px;
  --_slider-bg-clr: #005cbf;
  --_slider-bg-clr-on: #005cbf;
  --_slider-txt-clr: #ffffff;
  --_label-padding: 0.5rem 1rem;
  --_switch-easing: cubic-bezier(0.47, 1.64, 0.41, 0.8);

  color: white;
  width: fit-content;
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 9999px;
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  position: relative;
  isolation: isolate;
  margin: 0 0 0 auto;
  font-weight: 400;
}

.switch-view > span {
  display: grid;
  place-content: center;
  transition: opacity 300ms ease-in-out 150ms;
  padding: var(--_label-padding);
}
.switch-view::before,
.switch-view::after {
  content: "";
  position: absolute;
  border-radius: inherit;
  transition: inset 150ms ease-in-out;
}
/* switch slider */
.switch-view::before {
  background-color: var(--_slider-bg-clr);
  inset: var(--_switch-padding) 50% var(--_switch-padding) var(--_switch-padding);
  transition: inset 500ms var(--_switch-easing), background-color 500ms ease-in-out;
  z-index: -1;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.3);
}
/* switch bg color */
.switch-view::after {
  background-color: var(--_switch-bg-clr);
  inset: 0;
  z-index: -2;
}
/* switch hover & focus */
.switch-view[data-view-type="single"]:hover > span:first-of-type,
.switch-view[data-view-type="group"]:hover > span:first-of-type,
.switch-view[data-view-type="single"]:hover > span:last-of-type,
.switch-view[data-view-type="group"]:hover > span:last-of-type {
  opacity: 1;
  transition-delay: 0ms;
  transition-duration: 100ms;
}

/* checked - move slider to right */
.switch-view[data-view-type="group"]::before {
  background-color: var(--_slider-bg-clr-on);
  inset: var(--_switch-padding) var(--_switch-padding) var(--_switch-padding) 50%;
}
/* checked - set opacity */
.switch-view > .switch-btn:first-of-type {
  opacity: 0.75;
}
.switch-view > .switch-btn:last-of-type {
  opacity: 1;
}

// Full photo list styles
.full-photo-list {
  position: fixed;
  display: flex;
  justify-content: space-around;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: opacity 150ms ease-out;
  z-index: 1055;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  .fa-times {
    font-size: 25px;
    position: fixed;
    top: 31px;
    right: 14px;
    color: #fff;
    cursor: pointer;
    z-index: 2;
  }

  img {
    object-fit: contain;
    display: block;
    height: 100%;
  }

  .full-photo-item {
    position: relative;
    min-width: fit-content;
    margin: 0 5px;
    z-index: 1;

    &.loading {
      background: rgb(204 204 204 / 63%);
    }
  }

  .data-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .data-loader-content {
      padding: 0 !important;
    }
  }
}

.styled-link {
  display: inline-flex;
  align-items: center;
  background: #e1f1ff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2px 12px;
  text-decoration: none;
  font-weight: 600;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.styled-link:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.styled-link:active {
  transform: translateY(0);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.styled-link .icon {
  margin-right: 8px;
  font-size: 16px;
  flex-shrink: 0;
}

.styled-link .link-id {
  margin-right: 8px;
  color: #4f9bde;
  font-size: 13px;
  flex-shrink: 0;
}

.styled-link .link-title {
  font-size: 13px;
  color: #4f9bde;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  min-width: 0;
}

.orange-yellow-background {
  background-color: #ffc04c;
  border-radius: 4px;
  padding: 4px 8px;
  display: inline-block;
  font-weight: bold;
  font-size: 10px;
  font-family: "Lexend", sans-serif;
  color: #fff;
}

.status {
  border-radius: 8px;
  padding: 2px 12px;
  margin-right: 8px;
  color: white;
  display: inline-block;
}

.status.approved {
  background-color: rgb(20, 188, 20);
}

.status.rejected {
  background-color: rgb(240, 57, 57);
}
