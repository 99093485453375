.image-editor-wrapper {
  position: relative;

  .btn {
    margin: 10px;
  }
  .canvas-container {
    position: relative;
    margin: 0 auto;
  }
  .canvas-layer {
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -300px; // TODO rewrite to dynamic values

    &.canvas-layer--active {
      z-index: 1;
    }
  }
}
.image-editor-text-container {
  position: absolute;
  border: 1px dashed red;
  font: 20px sans-serif;
  color: red;
  line-height: 1.2;
  text-align: left;
  z-index: 2;
}
