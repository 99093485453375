@import "src/layout/styles/theme-1-light";
@import "src/layout/styles/aside";
@import "src/layout/styles/header";
@import "styles/filters";
@import "src/layout/styles/datepicker";

body,
html {
  //min-width: 567px;
}

html {
  scroll-behavior: smooth;
}

h1 {
  font-size: 2em;
}
.form-group {
  text-align: left;
  z-index: auto;
  position: relative;

  label {
    padding-left: 3px;
    margin-bottom: 0.2rem;
    font-weight: inherit;
  }

  &.tags-group {
    .label {
      background: #fce05b;
      color: #000;
      font-weight: 400;
      padding: 5px 8px;
    }
  }
}
.merchandiserBlock {
  .form-group {
    z-index: auto;
  }

  .nav-item {
    .nav-link {
      text-transform: uppercase;
    }
  }
}
.answer-photo-wrapper {
  position: relative;
  width: 100%;
}
.page-header {
  padding: 15px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 10px;

  > div {
    display: flex;
    align-self: center;

    &:last-child:not(:first-child) {
      flex-flow: row-reverse;
    }
  }
}
.page-header-title {
  margin: 0;
  padding: 0;
}
.previewImage {
  max-width: 400px;
  width: 100%;
}
.form-control {
  height: auto;
}
.data-loader--spinner {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  align-content: center;
  background: rgba(255, 255, 255, 0.8);
  flex-grow: 1;

  .data-loader-content {
    flex: auto;
  }
}
.css-yk16xz-control,
.css-1pahdxg-control,
.css-g1d714-ValueContainer {
  //fast compensate jumping on hover
  height: 40px;
}
.btn-primary {
  &.disabled,
  &:disabled {
    pointer-events: none;
  }
}
.img-placeholder {
  font-family: "Helvetica", serif;
  font-weight: 300;
  line-height: 2;
  text-align: center;
  width: 100%;
  height: auto;
  display: block;
  position: relative;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    border-radius: 10px;
  }

  &:after {
    content: "\f127"" " attr(alt);
    display: block;
    font-size: 16px;
    font-style: normal;
    font-family: FontAwesome;
    color: rgb(100, 100, 100);
    position: absolute;
    top: 45%;
    left: 0;
    width: 100%;
    text-align: center;
  }
}
.h-show {
  display: block;
}
.h-hide {
  display: none;
}

.pagination {
  justify-content: center;
  margin: 10px;
}

.divorserLine {
  margin: 0.5rem 1rem;
}
.error-container {
  padding: 16px;
  line-height: 1.5;
}

.nav-tabs {
  z-index: auto;
}

.file-input {
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 30px;
  padding: 5px 10px;
  vertical-align: top;
}
.filter-bar {
  .file-input {
    min-width: 200px;
    margin-bottom: 5px;
  }
}
.taskBlock {
  .photo-wrapper {
    width: fit-content;
    height: 100%;
  }
}
.photo-carousel {
  position: relative;
  height: auto;
  .photo-list {
    display: flex;
    width: 100%;
    overflow: hidden;
    scroll-behavior: smooth;
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 1;
    position: relative;
    min-width: 210px;
    max-width: 400px;
    margin: 0 auto;
    .previewImage {
      width: 100%;
      height: auto;
      object-fit: contain;
      flex: 1 0 100%;
      transition: all 1s ease;
      opacity: 0;
      z-index: 1;
      // max-width: fit-content;
      &.active {
        display: inline-block;
        opacity: 1;
        z-index: 2;
      }

      &:hover {
        cursor: zoom-in;
      }
    }
  }
  .dot-list {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }
  .dot {
    height: 10px;
    width: 10px;
    margin: 0 0 0 3px;
    background-color: #ccc;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid #ccc;
    transition: border-color 0.6s ease;
    &:first-child {
      margin-left: 0;
    }
    &.active {
      background: #fff;
    }
  }
  .carousel-navigations {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }
  .slide-arrow {
    border: none;
    background: none;
    padding: 0;
    line-height: normal;
    font-size: 25px;
    color: #ccc;
    &:hover,
    &:active {
      color: #0a6ea0;
    }
    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .slide-arrow-next {
    margin-left: 30px;
  }
}

//.single-task-container {
.new-task-wrapper {
  margin: 0;
  background: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  .task-main-row {
    display: flex;
    flex-wrap: wrap;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }
  .edit-images-block {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 0;

    .dropzone {
      width: 100%;
      border: navajowhite;
      margin: 0 !important;
      padding: 20px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;

      div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .taskImagesEdit {
      margin: 0 auto;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: auto;
        padding: 0;
        pointer-events: none;
      }
      .editPhotoNav {
        width: 100%;
        position: absolute;
        top: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.67) 0%, rgba(255, 255, 255, 0) 100%);
        min-height: 45px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0;

        .deleteImage {
          font-size: 24px;
          line-height: 20px;
          vertical-align: top;
          color: #fff;
          margin: 10px;
          order: 2;

          &:hover,
          &:active {
            color: #0a6ea0;
          }
        }
      }
      .modal-dialog {
        margin: 0;
      }
      .canvas-container {
        display: flex;
        margin: 0 auto;
        width: fit-content;
        align-items: center;
        justify-content: center;
        min-height: 350px;
        height: 100%;
      }
      .details-buttons-container {
        position: absolute;
        bottom: 0;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        align-content: center;
        width: auto;
        height: 100%;
        right: auto;
        left: 0;
        background: rgba(0, 0, 0, 0.26);
        padding: 0;
        .btn {
          margin: 0 !important;
          display: flex;
          align-items: center;
          padding: 0 3px 0 10px;

          &:hover,
          &:active {
            i {
              color: #0a6ea0;
            }
          }
        }
      }
      .d-inline-block {
        position: relative;
      }
    }
    .image-editor-wrapper {
      border: 1;
      button {
        i {
          font-size: 24px;
          line-height: 20px;
          vertical-align: top;
          &:hover,
          &:active {
            color: #0a6ea0;
          }
        }
        &:hover,
        &:active {
          border: none;
        }
      }
      .canvas-layer {
        top: 0;
        margin: 0;
        transform: translateX(-50%);
      }
    }
  }
  .task-main-content {
    width: 100%;
    padding: 10px 0;
    margin: 0;
    border-radius: 10px;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .form-group {
      z-index: auto;
    }
  }

  &.boxForm.boxEdit {
    width: 100%;
  }
}
//}
//Visit
.active-row {
  cursor: pointer;
}

.visit-page {
  .react-bs-container-body {
    overflow: visible;

    table {
      .active-row {
        td {
          padding-top: 0;
          padding-bottom: 0;

          a {
            padding-top: 24px;
            padding-bottom: 24px;
            display: inline-block;
          }
        }
      }
    }
  }

  .active-row {
    strong {
      margin-bottom: 0.3rem;
      display: inline-block;
    }
  }

  .visit-action-icon {
    height: 20px;
    width: auto;
  }

  .time-icon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
  }

  p {
    margin-bottom: 0;
  }

  .time-block {
    color: #717171;
    display: flex;
    align-items: center;
  }

  .added-by {
    color: #717171;
  }

  .approved,
  .rejected,
  .pending {
    border-radius: 50%;
    background: #198754;
    width: 15px;
    height: 15px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 7px;
      left: 3px;
      width: 5px;
      height: 2px;
      background-color: #fff;
      transform: rotate(45deg);
    }

    &::after {
      content: "";
      position: absolute;
      top: 6px;
      left: 5px;
      width: 8px;
      height: 2px;
      background-color: #fff;
      transform: rotate(-45deg);
    }
  }
  .rejected {
    background: #f00;

    &::after {
      content: none;
    }

    &::before {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      width: 5px;
      height: 5px;
      background-color: #fff;
      border-radius: 50%;
      transform: none;
    }
  }
  .pending {
    background: #ffc33d;

    &::before {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      width: 5px;
      height: 5px;
      background-color: #fff;
      border-radius: 50%;
      transform: none;
    }

    &::after {
      content: none;
    }
  }

  .status-wrapper {
    position: relative;
    border-radius: 4px;
    height: 24px;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 4px;
    transition: all 0.5 ease;

    .approved-block,
    .pending-block,
    .rejected-block {
      height: 100%;
      line-height: 24px;
      width: auto;
      background: #94c64a;
      color: #fff;
      text-align: center;
      position: relative;
      border-radius: 4px 0 0 4px;
      transition: all 0.5 ease;

      .status-label-text {
        display: none;
        padding: 2px 4px;
        position: absolute;
        background: #94c64a;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
        top: -32px;
        white-space: nowrap;

        &::before {
          content: "";
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid #94c64a;
          position: absolute;
          bottom: -6px;
          right: 22%;
        }
      }

      &:hover {
        .status-label-text {
          display: block;
        }
      }
    }
    .pending-block {
      background: #ff914d;
      border-radius: 0;

      .status-label-text {
        background: #ff914d;
        top: 28px;

        &::before {
          border-top-color: #ff914d;
          bottom: auto;
          top: -6px;
          transform: rotate(180deg);
        }
      }
    }
    .rejected-block {
      background: #ff1616;
      border-radius: 0 4px 4px 0;

      .status-label-text {
        background: #ff1616;

        &::before {
          border-top-color: #ff1616;
        }
      }
    }

    &.full-empty-cell {
      justify-content: space-between;

      .approved-block,
      .pending-block,
      .rejected-block {
        width: 33.3%;
      }
    }

    &::after {
      content: "";
      border-radius: 0 4px 4px 0;
    }

    &:hover {
      overflow: visible;

      .approved-block,
      .pending-block,
      .rejected-block {
        border-radius: 0;
      }
    }
  }

  .visit-progress {
    text-align: center;
  }

  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th {
    padding: 24px 8px;
  }

  .table > thead > tr > th {
    padding: 8px;
  }

  .react-bs-table table td {
    overflow: visible;
  }

  .filter-navigations {
    display: flex;
    width: 100%;
  }

  .filtered-task-data {
    margin-top: 2rem;
  }

  .filter-bar {
    .react-select-custom-container,
    .custom-react-select__control,
    .custom-react-select__value-container {
      width: 100%;
    }

    .react-dropdown-container {
      width: calc(32.7% - 5px);
      margin: 0 0 5px 0;

      &.date-range {
        max-width: 192px;
        width: 100%;
      }
    }
  }

  .filter-datepicker {
    margin-bottom: 5px;
    margin-right: 0;
  }

  .pointFilter.react-dropdown-container--multiselect {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .react-dropdown-container {
      width: 65.5%;

      &:first-child {
        width: 34.5%;
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.visit-single-page {
  .back-to-visit {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    width: fit-content;

    &::before {
      content: "";
      background: url(../../assets/image/back-icon.svg) no-repeat;
      display: inline-block;
      width: 24px;
      height: 24px;
      background-size: cover;
    }
  }
  .visit-item-wrapper {
    gap: 15px;
    display: flex;
    align-items: center;
  }

  .author-avatar-wrapper {
    position: relative;
    margin-right: 10px;

    .visit-item-author-avatar {
      width: 70px;
      height: 70px;
      object-fit: cover;
    }

    .author-task-status {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;

      &.active {
        background: #94c64a;
      }

      &.inactive {
        background: #ccc;
      }
    }
  }

  .visit-item-avatar {
    margin-right: 10px;
    width: 70px;
    height: 70px;
    object-fit: cover;
  }

  .visit-item-author-avatar {
    border-radius: 50%;
  }

  .visit-item-info-wrapper {
    position: relative;

    p {
      margin: 0 0 5px 0;
    }

    .visit-item-name {
      font-size: 13px;
      font-weight: 600;
    }

    .added-time {
      color: #717171;
    }

    .status-label-text {
      border-radius: 8px;
      padding: 3px 6px;
      width: fit-content;
      margin-bottom: 0;
      font-size: 11px;
      box-shadow: 0 4px 4px 0 #00000040;

      &.approved-task {
        background: #94c64a;
      }
      &.no-approved-task {
        background: #ff1616;
        color: #fff;
      }
      &.task-on-check {
        background: yellow;
      }
    }
  }

  .visit-item-description,
  .visit-item-author-information {
    width: 29%;
    display: flex;
    align-items: center;
  }

  .visit-item-author-information {
    width: 20%;
  }

  .visit-item-author-information {
    p {
      margin: 0 0 5px 0;
    }

    .visit-author-name {
      font-size: 16px;
    }

    .visit-author-role,
    .visit-author-phone {
      color: #717171;
    }

    .visit-author-phone {
      margin-bottom: 0;
    }
  }

  .visit-single-action-block {
    display: flex;
    align-items: center;
    gap: 20px;

    .visit-quick-action-list {
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .visit-quick-item {
      margin: 0;
      padding: 0;
      cursor: pointer;

      &.copy-btn {
        background: url("../../assets/image/copy-icon.svg") no-repeat;
        width: 28px;
        height: 28px;
      }
      &.download-btn {
        background: url("../../assets/image/download-icon.svg") no-repeat;
        width: 24px;
        height: 24px;
      }
      &.locale-btn {
        background: url("../../assets/image/local-icon.svg") no-repeat;
        width: 27px;
        height: 27px;
      }

      &::marker {
        display: none;
      }
    }
  }

  .visit-single-btn-wrapper {
    display: flex;
    gap: 20px;

    button {
      font-weight: 600;
      color: #fff;
    }

    .approve-visit {
      background: #48a9fc;
    }

    .reject-visit {
      background: #fc5348;
    }
  }

  .time-block {
    color: #717171;
    display: flex;
    align-items: center;
    line-height: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    font-weight: 600;

    .time-icon {
      width: 15px;
      height: 15px;
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .tabs-wrapper {
    button.MuiTab-root {
      text-transform: capitalize !important;
      color: #000000 !important;

      &.Mui-selected {
        color: #1976d2 !important;
      }
    }

    .tabs-list {
      > .MuiBox-root {
        border: none;
      }
    }

    [role="tabpanel"] {
      > div {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  //report tab
  .visit-report-tab-wrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 20px;

    .visit-report-item {
      height: 100%;
      width: 100%;
      max-width: 215px;
      cursor: pointer;

      .photo-item-wrapper {
        background: var(--lgry);
        padding: 0;
        border: 5px solid var(--lgry);
        overflow: hidden;
        height: 100%;
        width: 100%;
        max-height: 266px;
        position: relative;

        &.active {
          border-color: var(--newBlue);
        }
      }

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }

      .photo-report-item-content {
        margin: 5px 0 0 0;
        width: 100%;
        word-wrap: break-word;
        padding: 0 5px;
        text-align: center;
      }

      &.active,
      &:hover {
        .photo-item-wrapper {
          border-color: var(--newBlue);
        }
      }
    }

    .photo-report-item-status {
      display: flex;
      align-items: center;
      padding: 15px 0;
      gap: 30px;
      justify-content: center;
      width: 100%;
      position: absolute;
      bottom: 0;
      background: linear-gradient(0, rgba(0, 0, 0, 0.67) 0%, rgba(255, 255, 255, 0) 100%);

      i {
        font-size: 20px;
        color: var(--white);
      }

      .task-approve {
        i {
          color: var(--green);
        }
      }

      .task-reject {
        i {
          color: var(--red);
        }
      }
    }
  }

  //task tab
  .visit-task-tab-wrapper {
    th {
      padding: 10px 15px !important;
    }

    .visit-task-tab-item {
      td {
        font-size: 13px;
        line-height: 19px;
        font-weight: 400;
        padding: 20px 15px;
        color: #202224;
      }

      .visit-task-status {
        font-weight: 600;
        padding: 4px 10px;
        width: 100%;
        display: block;
        text-align: center;
        border-radius: 3px;

        &.visit-completed {
          color: #00b69b;
          background: #00b69b2e;
        }

        &.visit-not-completed {
          color: #514f56;
          background: #514f562e;
        }

        &.visit-in-progress {
          color: #cb9e00;
          background: #ffc90c47;
        }
      }

      &.not-completed {
        td {
          color: #a8a8a8;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    .react-bs-table {
      > .react-bs-container-body {
        overflow: visible;
      }
    }

    .task-type-name {
      white-space: normal;
      word-wrap: break-word;
    }

    .visit-task-priority {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: 0;

      &.required-priority {
        background: #ff1616;
      }
      &.high-priority {
        background: #ffc90c;
      }
      &.medium-priority {
        background: #94c64a;
      }
      &.low-priority {
        background: #ccc;
      }
    }

    .visit-single-btn-wrapper {
      justify-content: flex-end;
      transition: all 0.5s ease;
      align-items: center;

      &.hidden {
        opacity: 0;
        height: 0;
      }

      &.visible {
        opacity: 1;
        height: 36px;
      }
    }

    .approve-visit {
      background: #78b13f;
      width: 41px;
      align-self: stretch;

      span {
        display: inline-block;
        transform: rotate(45deg);
        height: 20px;
        width: 12px;
        border-bottom: 4px solid #fff;
        border-right: 4px solid #fff;
      }
    }

    .reject-visit {
      background: #fc5348;
      font-size: 29px;
      line-height: 20px;
      width: 41px;
      align-self: stretch;
    }

    &.hidden {
      opacity: 0;
      height: 0;
    }

    &.visible {
      opacity: 1;
      height: 36px;
    }
  }

  .custom-error-block {
    width: fit-content !important;
    min-width: 250px;
    left: 50% !important;
    transform: translateX(-50%) !important;
    z-index: 1051;
  }

  .new-task-wrapper {
    .edit-images-block {
      display: none !important;
    }
  }
  .task-main-row {
    padding: 0 !important;
    width: 100%;
    max-width: none;
    flex: 0 0 auto;
  }

  .actions-block .cancel-image-popup {
    display: block;
  }

  .visit-task-answer-text {
    word-break: break-word;
    margin: 0;
  }

  .visit-task-tag {
    padding: 3px 8px;
    font-size: 12px;
    letter-spacing: 0.5px;
    background: #fce05b;
    color: #000;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    margin: 3px;
    word-break: break-word;
  }

  .photo-user {
    position: relative;

    .user-status {
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      background: #00b69b url(../../assets/image/check.svg) no-repeat center;
      border: 2px solid #fff;
    }
  }

  .visit-events-item-author-wrapper {
    font-weight: 600;
    font-size: 15px;
  }

  .visit-events-item-author {
    color: #4643fe;
  }

  .visit-events-item-action {
    font-size: 15px;
    text-transform: lowercase;
  }

  .visit-events-tab-wrapper {
    text-align: left;
  }

  .visit-events-item {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 14px;
    border-bottom: 1px solid #c9c8c8;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .event-user-event-time {
    font-size: 11px;
    color: #939090;
    margin-bottom: 5px;
  }

  .visit-events-statistic-list {
    color: #575555;
    font-size: 14px;

    .visit-events-item-statistic-value {
      margin: 0 5px;
    }

    p {
      margin-bottom: 0;

      &::before {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #575555;
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
      }
    }
  }

  .visit-events-item {
    color: #575555;
    font-size: 14px;

    .visit-events-item-task-link {
      margin: 0 5px;
      color: #4643fe;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.deadline {
  color: #ff1616;
}

.visit-item-action {
  position: relative;

  &:hover {
    .visit-action-nav {
      display: block;
    }
  }
}

.visit-action-nav {
  display: none;
  background: #dedede;
  position: absolute;
  top: 100%;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #e8e0e0;
  border-radius: 5px;

  li {
    padding: 5px 10px;

    &:hover {
      background: #94c64a;
    }
  }
}

//monitoring tab
.visit-monitoring-tab-wrapper {
  .monitoring-product-img-col {
    .monitoring-product-img {
      width: auto;
      height: 200px;
      object-fit: contain;
    }

    .monitoring-product-empty-img {
      display: flex;
      align-items: center;
      min-height: 110px;
      margin-bottom: 0;
    }
  }

  .visit-monitoring-tab-item {
    td:first-child {
      padding-left: 15px;
      white-space: normal !important;
    }
  }

  .visit-item-action {
    .visit-action-icon {
      height: 20px;
      width: auto;
    }
  }

  .visit-task-status {
    font-weight: 600;
    padding: 4px 10px;
    width: 100%;
    display: block;
    text-align: center;
    border-radius: 3px;

    &.task-status-approved {
      color: #00b69b;
      background: #00b69b2e;
    }

    &.task-status-rejected {
      color: #fc5348;
      background: rgb(252 84 72 / 17%);
    }

    &.task-status-pending {
      color: #cb9e00;
      background: #ffc90c47;
    }
  }

  .visit-single-btn-wrapper {
    justify-content: flex-end;
    transition: all 0.5s ease;
    align-items: center;

    .approve-visit {
      background: #78b13f;
      width: 41px;
      align-self: stretch;

      span {
        display: inline-block;
        transform: rotate(45deg);
        height: 20px;
        width: 12px;
        border-bottom: 4px solid #fff;
        border-right: 4px solid #fff;
      }
    }

    .reject-visit {
      background: #fc5348;
      font-size: 29px;
      line-height: 20px;
      width: 41px;
      align-self: stretch;
    }

    &.hidden {
      opacity: 0;
      height: 0;
    }

    &.visible {
      opacity: 1;
      height: 36px;
    }
  }
}

.lazy-visit-downloading {
  margin-top: 40px;
}
