.table-tasks-import {
  &-delete {
    color: red;
  }
  textarea {
    width: 100%;
    height: auto;
  }
  input {
    height: 30px;
  }

  td {
    text-align: center;
  }

  .btn {
    i {
      font-size: 16px;
    }
  }
}
.upload-logs-container {
  display: flex;
  gap: 5px 20px;
  flex-wrap: wrap;

  div {
    width: 200px;
  }

  .upload-logs-remove {
    text-align: center;

    .faIcon {
      color: red;
      cursor: pointer;
    }
  }
  .upload-logs-row-small {
    width: 100px;
  }

  .row-title {
    font-weight: bold;
    letter-spacing: 0.5px;
  }
}
.upload-logs-row {
}
.flex-break-line {
  flex-basis: 100%;
  height: 0;
  overflow: hidden;
}
.error-task-list {
  display: flex;
  width: 100%;
  gap: 1%;
  flex-wrap: wrap;

  div {
    width: 13%;
    border-bottom: 1px solid #eee;
    padding: 3px;
  }
}

.dropzone-flex {
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 0.25rem;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}
