.app-aside {
  position: fixed !important;
  z-index: 30;
  float: left;
  height: 100%;
}

.app-content {
  box-shadow: none;
}
.app-content::after {
  display: block;
  content: "";
  clear: both;
}

.app-header {
  position: sticky;
  top: 0;
  z-index: 20;
}

.app-body-inner {
  padding-top: inherit !important;
  padding-bottom: inherit !important;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: auto;
}

.app-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  margin: inherit;
}
.app-footer:not(.hide) ~ .app-body {
  padding-bottom: 3.125rem;
}

.app-fixed {
  overflow: hidden;
}
.app-fixed .app {
  height: 100%;
}
.app-fixed .app-content {
  position: relative;
  min-height: 100%;
}
.app-fixed .app-footer {
  margin-left: 0;
}



/* fix ie9 */
.ie9 .app-aside {
  position: static !important;
}
.ie9 .app-aside .left {
  width: inherit;
  position: absolute;
}

.modal .left {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -moz-transition: -moz-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: -o-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.modal .right {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -moz-transition: -moz-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: -o-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.modal .top {
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -moz-transition: -moz-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: -o-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.modal .bottom {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -moz-transition: -moz-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition: -o-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.modal.in .left,
.modal.in .right,
.modal.in .top,
.modal.in .bottom,
.modal.show .left,
.modal.show .right,
.modal.show .top,
.modal.show .bottom {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.modal.inactive {
  bottom: auto;
  overflow: visible;
}

.modal-open-aside {
  overflow: visible;
}

.modal > .left {
  overflow: auto;
  position: fixed;
  z-index: 1055;
  top: 0;
  left: 0;
  right: auto;
  bottom: 0;
}

.modal > .right {
  position: fixed;
  z-index: 1055;
  top: 0;
  left: auto;
  right: 0;
  bottom: 0;
}

.modal > .top {
  position: fixed;
  z-index: 1055;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
}

.modal > .bottom {
  position: fixed;
  z-index: 1055;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal-header,
.modal-footer {
  border-color: rgba(120, 130, 140, 0.13);
}

.modal-content {
  color: rgba(0, 0, 0, 0.87);
}

.aside {
  color: rgba(0, 0, 0, 0.87);
}

.aside-header,
.aside-header .close {
  color: inherit !important;
  background-color: transparent !important;
}

.aside-header,
.aside-footer {
  border-color: rgba(120, 130, 140, 0.13) !important;
}

.aside-title {
  font-size: 1.2rem;
  margin: 0.45rem 0;
}

.modal.animate .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal.animate .fade-right-big {
  -webkit-animation: fadeOutLeftBig 0.5s;
  animation: fadeOutLeftBig 0.5s;
}

.modal.animate .fade-left-big {
  -webkit-animation: fadeOutRightBig 0.5s;
  animation: fadeOutRightBig 0.5s;
}

.modal.animate .fade-up-big {
  -webkit-animation: fadeOutUpBig 0.5s;
  animation: fadeOutUpBig 0.5s;
}

.modal.animate .fade-down-big {
  -webkit-animation: fadeOutDownBig 0.5s;
  animation: fadeOutDownBig 0.5s;
}

.modal.animate .fade-right {
  -webkit-animation: fadeOutLeft 0.5s;
  animation: fadeOutLeft 0.5s;
}

.modal.animate .fade-left {
  -webkit-animation: fadeOutRight 0.5s;
  animation: fadeOutRight 0.5s;
}

.modal.animate .fade-up {
  -webkit-animation: fadeOutUp 0.5s;
  animation: fadeOutUp 0.5s;
}

.modal.animate .fade-down {
  -webkit-animation: fadeOutDown 0.5s;
  animation: fadeOutDown 0.5s;
}

.modal.animate .flip-x {
  -webkit-animation: flipOutX 0.5s;
  animation: flipOutX 0.5s;
}

.modal.animate .flip-y {
  -webkit-animation: flipOutY 0.5s;
  animation: flipOutY 0.5s;
}

.modal.animate .zoom {
  -webkit-animation: zoomOut 0.5s;
  animation: zoomOut 0.5s;
}

.modal.animate .roll {
  -webkit-animation: rollOut 0.5s;
  animation: rollOut 0.5s;
}

.modal.animate .bounce {
  -webkit-animation: bounceOut 0.5s;
  animation: bounceOut 0.5s;
}

.modal.animate .rotate {
  -webkit-animation: rotateOut 0.5s;
  animation: rotateOut 0.5s;
}

.modal.animate.in .fade-right-big,
.modal.animate.show .fade-right-big {
  -webkit-animation: fadeInRightBig 0.5s;
  animation: fadeInRightBig 0.5s;
}

.modal.animate.in .fade-left-big,
.modal.animate.show .fade-left-big {
  -webkit-animation: fadeInLeftBig 0.5s;
  animation: fadeInLeftBig 0.5s;
}

.modal.animate.in .fade-up-big,
.modal.animate.show .fade-up-big {
  -webkit-animation: fadeInUpBig 0.5s;
  animation: fadeInUpBig 0.5s;
}

.modal.animate.in .fade-down-big,
.modal.animate.show .fade-down-big {
  -webkit-animation: fadeInDownBig 0.5s;
  animation: fadeInDownBig 0.5s;
}

.modal.animate.in .fade-right,
.modal.animate.show .fade-right {
  -webkit-animation: fadeInRight 0.5s;
  animation: fadeInRight 0.5s;
}

.modal.animate.in .fade-left,
.modal.animate.show .fade-left {
  -webkit-animation: fadeInLeft 0.5s;
  animation: fadeInLeft 0.5s;
}

.modal.animate.in .fade-up,
.modal.animate.show .fade-up {
  -webkit-animation: fadeInUp 0.5s;
  animation: fadeInUp 0.5s;
}

.modal.animate.in .fade-down,
.modal.animate.show .fade-down {
  -webkit-animation: fadeInDown 0.5s;
  animation: fadeInDown 0.5s;
}

.modal.animate.in .flip-x,
.modal.animate.show .flip-x {
  -webkit-animation: flipInX 0.5s;
  animation: flipInX 0.5s;
}

.modal.animate.in .flip-y,
.modal.animate.show .flip-y {
  -webkit-animation: flipInY 0.5s;
  animation: flipInY 0.5s;
}

.modal.animate.in .zoom,
.modal.animate.show .zoom {
  -webkit-animation: zoomIn 0.5s;
  animation: zoomIn 0.5s;
}

.modal.animate.in .roll,
.modal.animate.show .roll {
  -webkit-animation: rollIn 0.5s;
  animation: rollIn 0.5s;
}

.modal.animate.in .bounce,
.modal.animate.show .bounce {
  -webkit-animation: bounceIn 0.5s;
  animation: bounceIn 0.5s;
}

.modal.animate.in .rotate,
.modal.animate.show .rotate {
  -webkit-animation: rotateIn 0.5s;
  animation: rotateIn 0.5s;
}

@media (min-width: 992px) {
  .app-aside {
    opacity: 1;
    display: block !important;
  }
  /*.app-aside,*/
  /*.app-aside .scroll {*/
  /*width: 13.5rem; }*/
  .app-aside,
  .app-aside .scroll {
    width: 260px;
  }
  .app-aside.lg:not(.folded),
  .app-aside.lg:not(.folded) .scroll {
    width: 13.75rem;
  }
  .app-aside.lg:not(.hide) ~ .app-content {
    margin-left: 13.75rem;
  }
  .app-aside.sm:not(.folded),
  .app-aside.sm:not(.folded) .scroll {
    width: 11.25rem;
  }
  .app-aside.sm:not(.hide) ~ .app-content {
    margin-left: 11.25rem;
  }
  .app-aside.folded {
    width: 4rem;
  }
  .app-aside.folded:not(.hide) ~ .app-content {
    margin-left: 4rem;
  }
  .app-aside.folded.md {
    width: 5rem;
  }
  .app-aside.folded.md:not(.hide) ~ .app-content {
    margin-left: 5rem;
  }
  /*.app-aside:not(.hide) ~ .app-content {*/
  /*margin-left: 12.5rem; }*/
  .app-aside:not(.hide) ~ .app-content {
    margin-left: 260px;
  }
  .app-aside .left {
    position: absolute;
    right: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .hide-scroll {
    width: auto;
    margin-right: -17px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991px) {
  .app-aside.modal {
    width: 100%;
    position: fixed;
    z-index: 1050;
  }
  .app-aside .left {
    position: fixed;
    width: 304px;
  }
}

@media print {
  .app-aside,
  .app-header,
  .app-footer,
  .switcher {
    display: none;
  }
}

@media (min-width: 992px) {
  body.container {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
  body.container,
  body.container .container {
    padding-left: 0;
    padding-right: 0;
  }
  body.container .app-aside {
    left: inherit;
    right: auto;
  }
  body.container .app-aside.lg ~ .app-content .app-header {
    width: 45rem;
  }
  body.container .app-aside.sm ~ .app-content .app-header {
    width: 47.5rem;
  }
  body.container .app-aside.folded ~ .app-content .app-header {
    width: 54.75rem;
  }
  body.container .app-aside.folded.md ~ .app-content .app-header {
    width: 53.75rem;
  }
  body.container .app-aside ~ .app-content .app-header {
    width: 46.25rem;
  }
  body.container .app-header {
    left: inherit;
    right: auto;
    margin-left: 0;
    width: 58.75rem;
  }
}

@media (min-width: 1200px) {
  body.container .app-aside.lg ~ .app-content .app-header {
    width: 57.5rem;
  }
  body.container .app-aside.sm ~ .app-content .app-header {
    width: 60rem;
  }
  body.container .app-aside.folded ~ .app-content .app-header {
    width: 67.25rem;
  }
  body.container .app-aside.folded.md ~ .app-content .app-header {
    width: 66.25rem;
  }
  body.container .app-aside ~ .app-content .app-header {
    width: 58.75rem;
  }
  body.container .app-header {
    width: 71.25rem;
  }
}
