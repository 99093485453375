.task-form-group {
  clear: both;
  float: left;
  width: 100%;
  margin-top: 4px;

  label {
    display: flex;
    align-items: center;
    float: left;
    clear: both;
    font-size: 13px;
    font-weight: normal;
    color: #333;

    input[type="checkbox"] {
      margin: 2px 0.5em 3px 1px;
    }
  }
}
.filtered-task-data {
  position: relative;

  .data-loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    align-content: center;
    background: rgba(40, 57, 105, 0.2);
    flex-grow: 1;

    .data-loader-content {
      flex: auto;
    }
  }

  .task-row-item {
    span.label {
      font-weight: 600;
      padding: 4px 10px;
      width: 100%;
      display: block;
      text-align: center;
      border-radius: 3px !important;
      font-size: 13px;
      line-height: 19px;

      &.completed {
        color: #00b69b;
        background: rgba(0, 182, 155, 0.1803921569);
      }

      &.not-completed {
        color: #514f56;
        background: rgba(81, 79, 86, 0.1803921569);
      }

      &.in-progress {
        color: #cb9e00;
        background: rgba(255, 201, 12, 0.2784313725);
      }
    }
  }
}

.task-info {
  span.label {
    font-weight: 600;
    padding: 4px 10px;
    width: 100%;
    display: block;
    text-align: center;
    border-radius: 3px !important;
    font-size: 13px;
    line-height: 19px;

    &.completed {
      color: #00b69b;
      background: rgba(0, 182, 155, 0.1803921569);
    }

    &.not-completed {
      color: #514f56;
      background: rgba(81, 79, 86, 0.1803921569);
    }

    &.in-progress {
      color: #cb9e00;
      background: rgba(255, 201, 12, 0.2784313725) !important;
    }
  }
}

@media print {
  .row {
    display: block;
  }
  #aside,
  .app-header,
  .print-report-container {
    display: none;
  }
  .task-container {
    .task-header {
      display: none;
    }
  }
  .task-answers-list {
    display: block;
  }
  .task-answer {
    page-break-before: always;
    width: 100%;
  }
  .task-photo {
    flex: 1 0 0;
  }
  .task-answer-photo-container {
    overflow: visible;
    page-break-before: always;
  }
}
