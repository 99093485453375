@import "/assets/font/Roboto/font.css";

:root {
  --main-bg: #3b3b3b;
  --main-black-color: #333;
  --color-white: #fff;
  --font-family-main: "Roboto", sans-serif;
  --newBlue: #00abebff;
  --light-gray: #eee;
}
body {
  font-family: var(--font-family-main);
}
.theme-1-light {
  .primary {
    color: #fff;
    background-color: var(--newBlue);
  }
  .navbar-hd {
    background: var(--main-bg);
    border-bottom: 1px solid var(--main-bg);
    position: sticky;
    top: 0;
    z-index: 2;
  }
  .navside {
    .nav-text {
      opacity: 1;
    }
    .nav {
      li {
        min-height: auto;
      }
    }
  }
  .box-shadow-z4,
  .box-shadow-z3 {
    box-shadow: none;

    .box {
      box-shadow: none;
    }
  }
  .page-header {
    margin: 0;
  }
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .themed-main-table-container {
    overflow: auto;
    margin: 0.5em 0;

    .react-bs-table {
      min-width: 1200px;
    }
  }
  .pagination,
  .pager {
    .page-item.active,
    > .active > a,
    > .active > span {
      background-color: var(--newBlue);
    }
  }
  .app-header {
    background: var(--main-bg);
    color: var(--color-white);
  }
  .app-aside .left {
    background-color: var(--color-white);
    border-right: 1px solid var(--light-gray);
    color: var(--main-black-color);
  }
  .react-bs-table {
    .react-bs-container-header {
      width: fit-content;
      
      table {
        border-bottom: 0 none;
      }
    }

    table {
      border: 1px solid var(--light-gray);
      border-top: 0 none;

      td,
      th {
        white-space: normal;
        border-color: var(--light-gray);

        &:first-child {
          white-space: nowrap;
        }
      }
    }
    thead {
      th {
        //border-bottom: 1px solid var(--light-gray);
        border-bottom: 0 none;
      }
    }
    > thead {
      > tr {
        > td,
        > th {
          //font-weight: normal;
          border-bottom-width: 1px;
        }
      }
    }
  }

  .label.rounded {
    padding: 3px 8px;
    font-size: 12px;
    letter-spacing: 0.5px;

    &.in-progress {
      background-color: orange;
    }
    &.on-review, &.received {
      background-color: darkorange;
    }
    &.done {
      background-color: limegreen;
    }
    &:empty {
      display: none;
    }
  }

  .btn {
    font-size: 14px;
    padding: 0.25rem 0.75rem;

    i {
      font-size: 12px;
    }

    &:not(.btn-sm) {
      i {
        margin-right: 6px;
      }
    }
  }
  table thead {
    background-color: var(--color-white);
    color: var(--main-black-color);
    text-transform: none;
    letter-spacing: 0.5px;
  }
}
