.location_list{
    margin-bottom: 20px;
    display: flex;
    align-items: baseline;
}
.location_item{
    width: 30px;
    height: 30px;
    margin-right: 6px;
    border-radius: 5px;
    background-color: #C4C4C4;
}

.map_selected{
    margin-bottom: 5px;
    display: flex;
    align-items: baseline;
}
.map_selected p{
    font-size: 14px;
    margin-right: 5px;
}
.map_selected div{
    width: 12px;
    height: 12px;
    margin-right: 5px;
    border-radius: 2px;
    background-color: #C4C4C4;
}