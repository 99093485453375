.pointFilter {
  padding: 0;
  border: 0 none;
}
.clearFilter {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}
.filter-bar {
  position: relative;
  z-index: 9;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;

  .pointFilter  {
    gap: 5px;
  }
  
  .btn {
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 3px;
    vertical-align: top;

    &.btn-active {
      background-color: #0062cc;
      border-color: #005cbf;
    }
  }

  .css-yk16xz-control,
  .css-1pahdxg-control,
  .css-g1d714-ValueContainer,
  .css-1hwfws3, // TODO refactor with config styles or with cascades
  .react-datepicker__input-container .datePicker,
  .form-control,
  .react-dropdown-container > div > div {
    height: 30px;
    min-height: 30px;
  }
  .css-b8ldur-Input {
    height: 18px;
  }
  .css-2b097c-container {
    display: inline-block;
  }

  .react-datepicker-wrapper {
    display: inline-block !important;
  }

  .react-dropdown-container {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    vertical-align: top;
  }
  .css-yk16xz-control,
  .css-1pahdxg-control,
  .css-g1d714-ValueContainer,
  .css-2b097c-container,
  .filter-datepicker,
  .form-control {
    width: 260px;
  }

  .react-dropdown-container {
    height: 30px;
    position: relative;
    overflow: visible;
  }

  .css-g1d714-ValueContainer {
    padding-top: 3px;
  }

  .css-1wy0on6,
  .css-1hb7zxy-IndicatorsContainer {
    align-items: flex-start;
  }

  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding: 5px;
  }

  .react-dropdown-container--multiselect {
    .css-1pahdxg-control {
      height: auto !important;

      .css-g1d714-ValueContainer,
      .css-1hwfws3,
      > :first-child {
        // f..ng generated styles without customization
        height: auto !important;
      }
    }
  }

  .form-control {
    font-size: 12px;
    vertical-align: top;

    //&::placeholder, // TODO short description isn't working, use annotation below
    //&::-ms-input-placeholder,
    //&::-webkit-input-placeholder,
    //&::-moz-placeholder {
    //  color: #808080;
    //  opacity: 1;
    //}
  }
}
.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #808080;
  opacity: 1;
}
.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #808080;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #808080;
  opacity: 1;
}

.task-list {
  .filter-bar {
    .filter-datepicker {
      width: 405px;
    }
  }
}
