.user{
    height: 75px;
    margin: 20px 0;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F4F4F4;
    border-radius: 20px;
}
.user .avatar img{
    width: 40px;
    height: 40px;
}
.user p{
    font-size: 14px;
}
.user .status{
    width: 20px;
    height: 20px;
}
