.task-review-details-container {
  .form-group {
    label {
      padding-left: 0;
    }
    p {
      word-break: break-word;
    }
  }
}
.taskBlock {
  &.reviewTaskPreview {
      .photoCanvas.photo-carousel {
        .previewImage {
          &:hover {
            cursor: default;
          }
        }
      }
  }
}