.react-datepicker__input-container {
  .datePicker {
    width: 100%;
    min-height: 38px;
    margin-bottom: 0;
  }
}
.filter-datepicker {
  display: inline-flex;
  vertical-align: top;
  width: 230px;
  margin-right: 5px;
  gap: 5px;

  .react-datepicker__close-icon {
    margin-bottom: 5px;
    height: auto;
    bottom: 0;

    &:after {
      background-color: transparent;
      color: #ccc;
      font-size: 23px;
      font-weight: 500;
      text-shadow: 0.5px 0 #ccc;
    }
  }
  .react-datepicker-ignore-onclickoutside + .react-datepicker__close-icon:after {
    color: #333;
    text-shadow: 0.5px 0 #333;
  }

  .react-datepicker__tab-loop {
    position: absolute;
  }
}
