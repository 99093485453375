.red{
    background-color: red;
}
.yellow{
    background-color: #F4EB00;
}
.green{
    background-color: #2BE10D;
}
.dark_grey{
    background-color: #6F6F6F;
}
.status{
    border: 2px solid #ffffff;
    border-radius: 50%;
}
