.content_wrap{
    flex-grow: 1;
    padding: 20px;
}
.dashboard h5{
    padding-bottom: 10px;
}
.dashboard .user .location{
    font-size: 12px;
}
