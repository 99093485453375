.bg_blue{
    background-color: #00B8F2;
}
.bg_green{
    background-color: #14A38B;
}
.bg_dark_blue{
    background-color: #0880AE;
}
.bg_orange{
    background-color: #F2AC57;
}
.bg_coral{
    background-color: #FF7171;
}

.statistics_wrapper{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 15px;
    grid-auto-rows: minmax(110px, auto);
}
.statistics_wrapper div{
    border-radius: 20px;
}
.one {
    grid-column: 1 / 6;
    grid-row: 1/3;
}
.two {
    grid-column: 6 / 9;
    grid-row: 1;
}
.three {
    grid-column: 9 / 13;
    grid-row: 1;
}
.four {
    grid-column: 6 / 9;
    grid-row: 2;
}
.five {
    grid-column: 9 / 13;
    grid-row: 2;
}
