.map .google-map{
    height: 550px;
    border-radius: 20px;
    overflow: hidden;
}
.map .pin{
    width: max-content;
    background-color: #fff;
    border-radius: 20px;
    padding: 10px;
    display: flex;
}
.map .pin div:first-child{
    width: 35px;
    height: 35px;
    background-color: #C4C4C4;
    margin-right: 5px;
    border-radius: 5px;
    position: relative;
}

.map .pin_name{
    font-size: 12px;
    font-weight: 700;
}
.map .pin_text{
    font-size: 8px;
}
.map .status{
    position: absolute;
    width: 7px;
    height: 7px;
    border: none;
    bottom: 3px;
    right: 3px;
}