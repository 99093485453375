.navbar_avatar {
  display: inline-block;
  margin-top: -22px;
}

.nav-item-avatar {
  height: 15px;
  min-width: 200px;
  margin-top: -5px;
}

.avatar-label {
  display: inline-block;
  vertical-align: middle;
}

.headerAvatarCompany {
  display: inline-block;
  text-align: center;
  font-size: 12px;
  vertical-align: middle;
  margin-top: 5px;
}

.nav-item-avatar .block {
  display: inline-block;
  vertical-align: middle;
}

.headerUserBar {
  text-align: right;
}

@media (max-width: 892px) {
  .nav-item-avatar {
    margin-top: -10px;
  }
}
